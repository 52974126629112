import Mock from 'mockjs'

export default {
	getMenu: config => {
		const { username, password } = JSON.parse(config.body)
		// 先判断用户是否存在
		// 判断账号和密码是否对应
		// if (username === 'admin' && password === 'admin') {
			return {
				code: 20000,
				data: {
					menu: [
						{
							path: '/',
							name: 'home',
							label: '商品管理',
							icon: 's-home',
							url: 'home/index'
						},
					],
					token: Mock.Random.guid(),
					message: '获取成功'
				}
			}
		// } else if (username === 'xiaoxiao' && password === 'xiaoxiao') {
		// 	return {
		// 		code: 20000,
		// 		data: {
		// 			menu: [
		// 				{
		// 					path: '/',
		// 					name: 'home',
		// 					label: 'R0阻值录入',
		// 					icon: 's-home',
		// 					url: 'home/index'
		// 				},
		// 				{
		// 					path: '/mall',
		// 					name: 'mall',
		// 					label: 'SN码绑定组件码',
		// 					icon: 'video-play',
		// 					url: 'mall/index'
		// 				},
		// 			],
		// 			token: Mock.Random.guid(),
		// 			message: '获取成功'
		// 		}
		// 	}
		// } else {
		// 	return {
		// 		code: -999,
		// 		data: {
		// 			message: '密码错误'
		// 		}
		// 	}
		// }
	}
}
// 引入vue
import Vue from 'vue'
// 引入路由
import VueRouter from "vue-router";
// 引入组件
// import Home from '../views/Home.vue'

// 使用路由
Vue.use(VueRouter)

// 使用push方法跳转路由报错 重复点击路由，导致提示避免到当前位置的冗余导航 解决方法
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
	return routerRePush.call(this, location).catch(error => error)
}

// 配置路由组件
const routes = [
	// {
	// 	path: '/',
	// 	name: 'Main',
	// 	// component: Home
	// 	// 按需引入组件
	// 	component: () => import('../views/Main.vue'),
	// 	redirect: '/login',
	// 	// 子路由
	// 	children: [
	// 		{
	// 			path: '/home',
	// 			name: 'home',
	// 			component: () => import('../views/Home')
	// 		},
	// 		{
	// 			path: '/OrderManage',
	// 			name: 'OrderManage',
	// 			component: () => import('../views/OrderManage/index')
	// 		},
	// 		{
	// 			path: '/ShopAssistantList',
	// 			name: 'ShopAssistantList',
	// 			component: () => import('../views/ShopAssistantList/index')
	// 		},
	// 		{
	// 			path: '/College',
	// 			name: 'College',
	// 			component: () => import('../views/College/index')
	// 		},
	// 		{
	// 			path: '/ImgUpload',
	// 			name: 'ImgUpload',
	// 			component: () => import('../views/ImgUpload/index')
	// 		},
	// 		{
	// 			path: '/userManage',
	// 			name: 'userManage',
	// 			component: () => import('../views/userManage/index')
	// 		},
	// 		{
	// 			path: '/roleConfig',
	// 			name: 'roleConfig',
	// 			component: () => import('../views/roleConfig/index')
	// 		},
	// 	]
	// },
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login/Login.vue')
	},
	{
		path: '/redirect/:path(.*)',
		component: () => import('../views/redirect/index')
	},
	{
		path: '*',
		name: "404",
		component: () => import('@/views/redirect/404.vue')
	},
]

export default new VueRouter({
	mode: 'hash', //浏览器工作模式
	routes,
})

import Vue from 'vue'
import App from './App.vue'
import './assets/css/index.css' //全局公共样式
import router from './router' //路由
import Router from 'vue-router'
import store from './store'; //Vuex
import axios from 'axios';
import './api/mock';
// import {Button,Container,Aside,Header,Main,Menu,Submenu,MenuItemGroup,MenuItem,Dropdown,DropdownItem} from 'element-ui'
import ElementUI, { MessageBox } from 'element-ui';
import {  getPermissionsList } from "@/api/data";
import 'element-ui/lib/theme-chalk/index.css';
import VueResource from 'vue-resource' 
import './directives' 
Vue.use(VueResource)
Vue.use(ElementUI)
// Vue.use(Button)
// Vue.use(Container)
// Vue.use(Aside)
// Vue.use(Header)
// Vue.use(Main)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
// Vue.use(Dropdown)
// Vue.use(DropdownItem)

Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$message = Message

function createObj(data, arr, buttonObj, parentCode, routerList) {
  if (Object.keys(data).length > 0) {
    var i = 0;
    for (let item in data) {
      // 如果是菜单&&状态已启用
      if (data[item].type == 1 && data[item].status == 1 ) {
        let obj = {
          code: data[item].permissions_code,
          is_hidden: data[item].is_hidden,
          label: data[item].permissions_name,
          is_hidden: data[item].is_hidden,
          sort: data[item].sort,
          name: data[item].permissions_code,
          path: "/" + data[item].interface_path,
          status: data[item].status,
          icon: data[item].icon,
          type: data[item].type,
          children: [],
        }
        arr.push(obj);
        if (data[item].interface_path != '/') {
          routerList.push(obj)
        }
        
        // 如果是按钮&&状态已启用
      } else if (data[item].type == 2 && data[item].status == 1) {
        buttonObj[parentCode].push(data[item].permissions_code);
      }

      if (Object.keys(data[item].children).length > 0) {
        let name = data[item].permissions_code;
        buttonObj[name] = [];
        createObj(
          data[item].children,
          arr[i].children,
          buttonObj,
          data[item].permissions_code,
          routerList
        );
      }
      i++;
    }
    return [arr, buttonObj, routerList];
  }
}
function getList (to, form) {
  getPermissionsList().then(({ data: res }) => {
    if (res.code === 200) {
      let obj = createObj(res.data, [], {}, null, [])
      let menu = obj[0] || [];
      menu.sort((a, b) => {
        return b.sort - a.sort;
      });
      let routerList = obj[2]

      localStorage.setItem('routerList', JSON.stringify(routerList))
      localStorage.setItem("menu", JSON.stringify(menu));
      localStorage.setItem("button_list", JSON.stringify(obj[1]));
      
      routerList = routerList.map((item, index) => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.map((it, ind) => {
            if (it.path != '//') {
              it.component = () => import('@/views' + it.path + '/index')
            }
            return it;
          });
        }
        if (item.path != '//') {
          item.component = () => import('@/views' + item.path + '/index')
        }
        return item;
      });
      let arr = [
        {
          path: "/",
          name: "Main",
          component: () => import("@/views/Main.vue"),
          redirect: "/login",
          children: routerList,
        },
      ];
      // 替换现有router的routes
      router.matcher = new Router({
        routes: [
          {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login/Login.vue"),
          },
          {
            path: "/redirect/:path(.*)",
            component: () => import("@/views/redirect/index"),
          },
          {
            path: "*",
            name: '404',
            component: () => import("@/views/redirect/404.vue"),
          },
        ],
      }).matcher;
      router.addRoutes(arr);
    } else {
      // this.$message.error(res.message);
    }
  });
}

// 导航守卫
router.beforeEach((to, from, next) => {
  store.commit('getToken') //防止Vuex丢失Token信息
  let token = store.state.user.token
  let routerList = JSON.parse(localStorage.getItem('routerList'))
  if (!from.name && token) {
    if (routerList) {
      setTimeout( () => {
        getList(to, from)
      }, 200)
    } else {
      store.commit('clearToken') 
    }
  }
  // console.log('获取到的所有路由', router.getRoutes())
  token = store.state.user.token
  if(!token && to.name !== 'login'){
    next({ name: 'login' })
  }else if(token && to.name === 'login'){
    let paths = JSON.parse(localStorage.getItem('menu'))
    localStorage.setItem('page', 'Home')
    next({name: 'Home'})
  } else{
    localStorage.setItem('page', to.name)
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
  // 全局事件总线
  beforeCreate() {
    // 将axios放到Vue的原型上，使全局可以使用
    Vue.prototype.axios = axios
  },
  created(){
  store.commit('addMenu',router)
}

}).$mount('#app')

import axios from 'axios'
import config from './config'
import Cookie from 'js-cookie'
import { Message, Loading } from 'element-ui'

// 判断当前开发 环境
// const baseUrl = process.env.NODE_ENV === 'development'
// 	? 'https://dev.backend.member.wonzi.com'
// 	: 'https://dev.backend.member.wonzi.com'
const baseUrl = 'http://dev.salesclerk.wonzi.com'

/* 当页面有两个接口时，第一个接口loading的close事件会直接将第二个接口的loading实例也close */
let loadingInstance = null
function startLoading() {
	loadingInstance = Loading.service({
		fullscreen: true,
		text: '拼命加载中...',
		background: 'rgba(0, 0, 0, 0.8)'
	})
	// 解决loading 被dialog 覆盖的情况
	let BOX = document.querySelector(".el-loading-mask");
	BOX.style.zIndex = '999999';//固定一个最大值，是字符串
}
function endLoading() {
	  loadingInstance.close()
}
let needLoadingRequestCount = 0
function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading()
	}
	needLoadingRequestCount++
}
function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}


class HttpRequest {
	constructor(baseUrl) {
		this.baseUrl = baseUrl
	}
	getInsideConfig() {
		const config = {
			baseUrl: this.baseUrl,
			timeout: 5000,
			header: {}
		}
		return config
	}
	// 拦截器
	interceptors(instance) {
		// 添加请求拦截器
		instance.interceptors.request.use(config => {
			// 在发送请求之前做些什么
			showFullScreenLoading()
			return config;
		}, error => {
			// 对请求错误做些什么
			tryHideFullScreenLoading()
			return Promise.reject(error);
		});

		// 添加响应拦截器
		instance.interceptors.response.use(response => {
			// 在响应之前做些什么
			tryHideFullScreenLoading()
			if (response.data.message === '请重新登录') {
				Cookie.remove('token')
				location.reload();
			}
			return response;
		}, error => {
			// 对响应错误做些什么
			tryHideFullScreenLoading()
			// console.log(error, 'error');
			return Promise.reject(error);
		});
	}
	// 接口请求时调用
	request(options) {
		const instance = axios.create()
		instance.defaults.headers.common['Authorization'] = 'Bearer ' + Cookie.get('token')
		options = { ...this.getInsideConfig(), ...options }
		// 调用拦截器，将创建的instance传入
		this.interceptors(instance)
		return instance(options)
	}
}

export default new HttpRequest(baseUrl)
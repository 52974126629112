import Vue from "vue";

/**权限指令**/
Vue.directive("has", {
  inserted: function (el, binding, vnode) {
    // 获取页面按钮权限
    let page = localStorage.getItem("page");
    let btnPermissions = JSON.parse(localStorage.getItem("button_list"));
    let btnPermissionsArr = btnPermissions[page];
    if (!btnPermissionsArr || btnPermissionsArr.indexOf(binding.value) === -1) {
      el.parentNode.removeChild(el);
    }
  },
});
Vue.prototype.$has = function (value) {
  let isExist = true;
  // 获取用户按钮权限
  let page = localStorage.getItem("page");
  let btnPermissions = JSON.parse(localStorage.getItem("button_list"));
  let btnPermissionsArr = btnPermissions[page];
  if (!btnPermissionsArr || btnPermissionsArr.indexOf(value) === -1) {
    isExist = false;
  }
  return isExist;
};

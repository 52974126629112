// 接口请求

import axios from './axios'

export const getMenu = (param) => {
	return axios.request({
		url: '/permission/getMenu',
		method: 'post',
		data: param
	})
}

export const getPermissionsList = (data) => {
	// 获取权限
	return axios.request({
		url: '/api/admin/getPermissionsList',
		method: 'post',
		data: data,
	})
}

export const getUserList = (param) => { // 获取用户列表
	return axios.request({
		url: '/api/admin/user/list',
		method: 'post',
		data: param
	})
}

export const delUser = (param) => { // 删除用户
	return axios.request({
		url: '/api/admin/user/del',
		method: 'post',
		data: param
	})
}

export const addUser = (param) => { // 新增用户
	return axios.request({
		url: '/api/admin/register',
		method: 'post',
		data: param
	})
}

export const editUser = (param) => { // 修改用户
	return axios.request({
		url: '/api/admin/user/edit',
		method: 'post',
		data: param
	})
}

export const rolelist = (param) => { // 角色list
	return axios.request({
		url: '/api/admin/role/list',
		method: 'post',
		data: param
	})
}

export const roledel = (param) => { // 角色del
	return axios.request({
		url: '/api/admin/role/del',
		method: 'post',
		data: param
	})
}

export const roleAdd = (param) => { // 角色add
	return axios.request({
		url: '/api/admin/role/add',
		method: 'post',
		data: param
	})
}

export const roleEdit = (param) => { // 角色Edit
	return axios.request({
		url: '/api/admin/role/edit',
		method: 'post',
		data: param
	})
}

export const userInfo = (param) => { // 查询用户
	return axios.request({
		url: '/api/admin/user/info',
		method: 'post',
		data: param
	})
}

export const mediaUpload = (param) => { // 图片上传
	return axios.request({
		url: '/api/admin/media/upload',
		method: 'post',
		data: param
	})
}

export const mediaSearch = (param) => { // 图片列表
	return axios.request({
		url: '/api/admin/media/search',
		method: 'post',
		data: param
	})
}

export const getimgUrl = (param) => { // get图片url
	return axios.request({
		url: '/api/admin/media/url',
		method: 'post',
		data: param
	})
}

export const mediaEditStatus = (param) => { // 修改img状态
	return axios.request({
		url: '/api/admin/media/edit/status',
		method: 'post',
		data: param
	})
}

export const homepageCreate = (param) => { // 首页创建
	return axios.request({
		url: '/api/admin/goods/save',
		method: 'post',
		data: param
	})
}

export const getHomelist = (param) => { // 商品列表
	return axios.request({
		url: '/api/admin/goods/list' + param,
		method: 'get',
	})
}

export const homepageUpdate = (param) => { // 修改商品状态
	return axios.request({
		url: '/api/admin/goods/update',
		method: 'post',
		data: param
	})
}

export const goods_delete = (param) => { // 批量删除商品
	return axios.request({
		url: '/api/admin/goods/delete',
		method: 'post',
		data: param
	})
}


export const getsalesclerklist = (param) => { // 会员信息汇总列表
	return axios.request({
		url: '/api/admin/salesclerk/list' + param,
		method: 'get',
	})
}

export const get_integral_list = (param) => { // 店员积分列表
	return axios.request({
		url: '/api/admin/integral/list' + param,
		method: 'get',
	})
}

export const get_invitation_list = (param) => { // 获取邀请人员列表
	return axios.request({
		url: '/api/admin/invitation/list' + param,
		method: 'get',
	})
}

export const userInfoSearch = (param) => { // 用户信息搜索
	return axios.request({
		url: '/api/admin/userInfo/search',
		method: 'post',
		data: param
	})
}

export const userInfoInfo = (param) => { // 用户信息详情
	return axios.request({
		url: '/api/admin/userInfo/info',
		method: 'post',
		data: param
	})
}

export const integralSearch = (param) => { // 搜索用户积分列表
	return axios.request({
		url: '/api/admin/integral/search',
		method: 'post',
		data: param
	})
}

export const collegeSave = (param) => { // 新增/修改 视频信息
	return axios.request({
		url: '/api/admin/college/save',
		method: 'post',
		data: param
	})
}

export const collegeUpdate = (param) => { // 批量修改视频状态
	return axios.request({
		url: '/api/admin/college/update',
		method: 'post',
		data: param
	})
}

export const collegeDelete = (param) => { // 批量删除视频
	return axios.request({
		url: '/api/admin/college/delete',
		method: 'post',
		data: param
	})
}

export const getcollegelist = (param) => { // 获取知识视频列表
	return axios.request({
		url: '/api/admin/college/list' + param,
		method: 'get',
	})
}

export const getorderlist = (param) => { // 获取订单列表
	return axios.request({
		url: '/api/admin/order/search' + param,
		method: 'get',
	})
}

export const department_list = (param) => { // 获取大区列表
	return axios.request({
		url: '/api/admin/department/list',
		method: 'post',
		data: param
	})
}

export const order_send = (param) => { // 发货及编辑
	return axios.request({
		url: '/api/admin/order/send',
		method: 'post',
		data: param
	})
}

export const login = (param) => {
	return axios.request({
		url: '/api/admin/login',
		method: 'post',
		data: param
	})
}

export const logout = (param) => {
	return axios.request({
		url: '/api/admin/logout',
		method: 'post',
		data: param
	})
}
export const getData = ()=>{
	return axios.request({
		url:'/home/getData',
	})
}
